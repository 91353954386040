import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/docs-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "zmk-firmware"
    }}>{`ZMK Firmware`}</h1>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`ZMK is a new, MIT-licensed firmware built on top of the `}<a parentName="p" {...{
        "href": "https://www.zephyrproject.org/"
      }}>{`Zephyr RTOS`}</a>{` that is "wireless first". It is still in the early stages, but has working functionality for a large portion of use cases.`}</p>
    <h3 {...{
      "id": "website"
    }}><a parentName="h3" {...{
        "href": "https://zmk.dev/"
      }}>{`Website`}</a></h3>
    <h3 {...{
      "id": "faq"
    }}><a parentName="h3" {...{
        "href": "https://zmk.dev/docs/faq/"
      }}>{`FAQ`}</a></h3>
    <h3 {...{
      "id": "getting-started"
    }}><a parentName="h3" {...{
        "href": "https://zmk.dev/docs/user-setup"
      }}>{`Getting Started`}</a></h3>
    <h3 {...{
      "id": "discord"
    }}><a parentName="h3" {...{
        "href": "https://zmk.dev/community/discord/invite"
      }}>{`Discord`}</a></h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      